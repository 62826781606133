var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Platform, Text, View } from "react-native";
import images from "../../assets";
import FormInputBase from "../FormInputBase";
import ListItemSelect from "../ListItemSelect";
import Spacer, { ESpacerWeight } from "../Spacer";
import * as S from "./FormInlineSelect.styles";
import { EFormInlineSelectDisplayType, } from "./FormInlineSelect.types";
var FormInlineSelect = /** @class */ (function (_super) {
    __extends(FormInlineSelect, _super);
    function FormInlineSelect() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Event handlers
        _this.handlePressListItemSelect = function (id) { return function () {
            _this.props.onSelect(id);
        }; };
        return _this;
    }
    // Lifecycle methods
    FormInlineSelect.prototype.componentWillMount = function () {
        this.props.getOptions(this.props.formData);
    };
    FormInlineSelect.prototype.render = function () {
        var _this = this;
        var _a = this.props, title = _a.title, info = _a.info, emptyMessage = _a.emptyMessage, selected = _a.selected, options = _a.options, _b = _a.extraOptions, extraOptions = _b === void 0 ? [] : _b, error = _a.error, optional = _a.optional, extraInfoUrl = _a.extraInfoUrl, onPressExtraInfo = _a.onPressExtraInfo, disabled = _a.disabled, isFetching = _a.isFetching, _c = _a.displayType, displayType = _c === void 0 ? EFormInlineSelectDisplayType.List : _c;
        var mergedOptions = __spreadArray(__spreadArray([], options, true), extraOptions, true);
        return (_jsxs(FormInputBase, __assign({ title: title, info: info, error: error, optional: optional, disabled: disabled, isFetching: isFetching, extraInfoUrl: extraInfoUrl, onPressExtraInfo: onPressExtraInfo, showBorder: false }, { children: [_jsx(Spacer, { weight: Platform.OS === "web" ? ESpacerWeight.W04 : ESpacerWeight.W08 }), displayType === EFormInlineSelectDisplayType.Card && (_jsx(S.CardsWrap, { children: mergedOptions.map(function (_a, index) {
                        var id = _a.id, value = _a.value, subValue = _a.subValue, icon = _a.icon, disabled = _a.disabled;
                        var isSelected = false;
                        if (Array.isArray(selected)) {
                            isSelected = selected.includes(id);
                        }
                        else {
                            isSelected = selected === id;
                        }
                        return (_jsx(S.CardOuterWrap, { children: _jsxs(S.CardInnerWrap, __assign({ onPress: _this.handlePressListItemSelect(id), selected: isSelected, isFirstOnRow: index % 3 === 0 }, { children: [_jsx(View, {}), icon && (_jsx(S.CardIcon, { selected: isSelected, source: icon })), _jsx(S.CardTitle, __assign({ selected: isSelected }, { children: value })), isSelected && (_jsx(S.CheckIcon, { source: images.ICO_CHECK_2 }))] })) }, index));
                    }) })), displayType === EFormInlineSelectDisplayType.List &&
                    mergedOptions.map(function (_a, index) {
                        var id = _a.id, value = _a.value, subValue = _a.subValue, disabled = _a.disabled;
                        var isSelected = false;
                        if (Array.isArray(selected)) {
                            isSelected = selected.includes(id);
                        }
                        else {
                            isSelected = selected === id;
                        }
                        return (_jsxs(React.Fragment, { children: [Platform.OS !== "web" && index !== 0 && _jsx(S.Separator, {}), _jsx(ListItemSelect, { title: value, subtitle: subValue, selected: isSelected, disabled: disabled, onPress: _this.handlePressListItemSelect(id), style: { padding: 0 } }), Platform.OS === "web" && _jsx(S.Separator, {})] }, index));
                    }), mergedOptions.length === 0 && !!emptyMessage && (_jsx(Text, { children: "emptyMessage" }))] })));
    };
    return FormInlineSelect;
}(React.PureComponent));
// eslint-disable-next-line import/no-default-export
export default FormInlineSelect;
