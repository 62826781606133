import { EContactType } from "../../types/contact.types";
import { getStore } from "../../utils/redux/redux.utils";
import { getContactMe, getContactMeMaster, } from "./contact.selectors";
export var getName = function (_a, companyWithName) {
    var firstname = _a.firstname, lastname = _a.lastname, company = _a.company;
    if (companyWithName === void 0) { companyWithName = true; }
    var result = [];
    if (firstname) {
        result.push(firstname);
    }
    if (lastname) {
        result.push(lastname);
    }
    if (company) {
        // cspell:disable-next-line
        // Show company name with personal name => e.g. Immo Stijn (Stijn Roels)
        if (companyWithName && result.length > 0) {
            return "".concat(company, " (").concat(result.join(" "), ")");
        }
        return company;
    }
    return result.join(" ");
};
export var getInitials = function (person) {
    return getName(person, false)
        .replace(/[{()}]/g, "")
        .split(" ")
        .map(function (word) { return word.substring(0, 1); })
        .slice(0, 2)
        .join("")
        .toUpperCase();
};
export var getMappedName = function (account, companyWithName) {
    if (companyWithName === void 0) { companyWithName = true; }
    var id = account.id;
    var store = getStore();
    if (!store) {
        return getName(account, companyWithName);
    }
    var state = store.getState();
    // Match with me
    var me = getContactMe(state);
    if (me === null || me === void 0 ? void 0 : me.accountIds.includes(id)) {
        return getName(me, companyWithName);
    }
    // Match with me master
    var meMaster = getContactMeMaster(state);
    if (meMaster === null || meMaster === void 0 ? void 0 : meMaster.accountIds.includes(id)) {
        return getName(meMaster, companyWithName);
    }
    return getName(account, companyWithName);
};
export var keyExtractorForContactListDataSourceItem = function (_a) {
    var contact = _a.contact, contactId = _a.contactId, account = _a.account, accountId = _a.accountId;
    if (account) {
        return "account-".concat(account.id);
    }
    if (accountId) {
        return "account-".concat(accountId);
    }
    if (contact) {
        return "contact-".concat(contact.id);
    }
    if (contactId) {
        return "contact-".concat(contactId);
    }
    return "id-not-found";
};
export var getIbanId = function (contactId, accountId) {
    if (contactId) {
        return "contact-".concat(contactId);
    }
    if (accountId) {
        return "account-".concat(accountId);
    }
};
export var hasConnection = function (contact) {
    if (contact.type === EContactType.Personal) {
        return true;
    }
    if (contact.inviteAcceptedAt) {
        return true;
    }
    return false;
};
