var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { IMaskInput } from "react-imask";
import { Platform } from "react-native";
import rentioTheme from "../../theme/theme.rentio";
import FormInputBase from "../FormInputBase";
import * as S from "./FormInputText.styles";
var FormInputText = /** @class */ (function (_super) {
    __extends(FormInputText, _super);
    function FormInputText() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // Render
    FormInputText.prototype.render = function () {
        var _a = this.props, title = _a.title, placeholder = _a.placeholder, onChangeValue = _a.onChangeValue, value = _a.value, error = _a.error, suffixText = _a.suffixText, info = _a.info, textInputProps = _a.textInputProps, disabled = _a.disabled, optional = _a.optional, showDisclosureIcon = _a.showDisclosureIcon, extraInfoUrl = _a.extraInfoUrl, onPressExtraInfo = _a.onPressExtraInfo, multiline = _a.multiline, iMaskProps = _a.iMaskProps, maxLength = _a.maxLength;
        return (_jsx(FormInputBase, __assign({ title: title, info: info, error: error, disabled: disabled, optional: optional, showDisclosureIcon: showDisclosureIcon, extraInfoUrl: extraInfoUrl, onPressExtraInfo: onPressExtraInfo }, { children: _jsxs(S.TextInputWrap, { children: [iMaskProps && Platform.OS === "web" ? (_jsx(IMaskInput, __assign({}, iMaskProps, { value: value, onAccept: onChangeValue, style: {
                            flex: 1,
                            padding: "16px",
                            fontSize: "14px",
                            color: rentioTheme.colors.neutral1,
                            placeholderTextColor: "#B9B9B9",
                            caretColor: rentioTheme.colors.neutral1,
                            border: "none",
                            backgroundColor: "transparent",
                            "::placeholder": {
                                color: "#B9B9B9",
                            },
                        } }))) : (_jsx(S.TextInputStyled, __assign({ autoFocus: false }, textInputProps, { placeholder: placeholder, value: value, onChangeText: onChangeValue, multiline: multiline, maxLength: maxLength }))), suffixText && _jsx(S.SuffixText, { children: suffixText })] }) })));
    };
    return FormInputText;
}(React.PureComponent));
// eslint-disable-next-line import/no-default-export
export default FormInputText;
