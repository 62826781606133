import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";

export const Wrap = styled.div`
  pointer-events: none;
  width: 100%;
  position: fixed;
  bottom: 24px;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    bottom: 16px;
  }
`;

export const InnerWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    padding: 0 16px;
  }
`;

export const FooterButton = styled.button`
  pointer-events: auto;
  position: relative;
  padding: 6px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.global5};
  font-size: 16px;
  color: ${props => props.theme.colors.neutral2};
  border-radius: 100px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    background-color: ${props => props.theme.colors.neutral2};
    padding: 1px;
  }
`;

export const FooterButtonIcon = styled.img`
  margin-right: 6px;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    margin-right: 0;
    width: 30px;
  }
`;

export const LanguageSwitcherTitle = styled.span`
  position: absolute;
  text-align: center;
  font-size: 12px;
  left: 0;
  right: 0;
  color: ${props => props.theme.colors.global5};
`;

export const Menu = styled.div`
  position: absolute;
  right: 44px;
  bottom: 0;
  width: 240px;
  background-color: ${props => props.theme.colors.neutral2};
  border-radius: 4px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
`;

export const MenuHeader = styled.div`
  color: ${props => props.theme.colors.global2};
  padding: 16px;
  font-size: 16px;
  text-align: left;
`;
