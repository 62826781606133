import { IProps as IFormInputBaseProps } from "@rentiohq/shared-frontend/dist/components/components/FormInputBase";
import { DatePickerContext } from "@rentiohq/shared-frontend/dist/components/components/FormInputDate";
import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { normalizeDate } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { getLocale } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import en from "date-fns/locale/en-US";
import fr from "date-fns/locale/fr";
import nl from "date-fns/locale/nl";
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerWrap = styled.div`
  .react-datepicker__input-container {
    display: none;
  }
`;

registerLocale(ELocale.NL, nl);
registerLocale(ELocale.FR, fr);
registerLocale(ELocale.EN, en);
registerLocale(ELocale.INTERNAL, en);

interface IConfig {
  formInputBaseProps: Partial<IFormInputBaseProps>;
  initialDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  showMonthYearPicker?: boolean;
  inverseYears?: boolean;
}

interface IState {
  onSubmit?: (date?: Date) => void;
  config?: IConfig;
}

class DatePickerProvider extends React.Component<
  {
    children?: React.ReactNode;
  },
  IState
> {
  // Initialize
  constructor(props: {}) {
    super(props);

    this.state = {};
  }

  // Render
  public render = () => {
    return (
      <DatePickerContext.Provider
        value={{
          showPicker: (onSubmit: (date?: Date) => void, config: IConfig) => {
            this.setState({
              onSubmit,
              config,
            });
          },
        }}
      >
        {this.props.children}
        {this.state.onSubmit && this.state.config && (
          <DatePickerWrap>
            <DatePicker
              locale={getLocale()}
              selected={this.getInitialDate()}
              minDate={this.getMinDate()}
              maxDate={this.getMaxDate()}
              showYearDropdown={true}
              showMonthDropdown={true}
              dropdownMode={"select"}
              startOpen={true}
              withPortal={true}
              onChange={this.setDate}
              // TODO: Check if still necessary (handler called twice with handleClosePicker)
              onClickOutside={this.handleClosePicker}
              onCalendarClose={this.handleClosePicker}
            />
          </DatePickerWrap>
        )}
      </DatePickerContext.Provider>
    );
  };

  // Event handlers
  private handleClosePicker = () => {
    this.setState({
      onSubmit: undefined,
      config: undefined,
    });
  };

  // Helpers
  private setDate = (newDate: Date) => {
    if (!this.state.onSubmit) {
      return;
    }

    this.state.onSubmit(newDate);
    this.handleClosePicker();
  };

  private getInitialDate = () => {
    const { config } = this.state;

    if (!config?.initialDate) {
      return undefined;
    }

    return normalizeDate(config.initialDate) as Date;
  };

  private getMinDate = () => {
    const { config } = this.state;

    if (!config?.minDate) {
      return undefined;
    }

    return normalizeDate(config.minDate) as Date;
  };

  private getMaxDate = () => {
    const { config } = this.state;

    if (!config?.maxDate) {
      return undefined;
    }

    return normalizeDate(config.maxDate) as Date;
  };
}

// eslint-disable-next-line import/no-default-export
export default DatePickerProvider;
