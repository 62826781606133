var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import * as S from "./ButtonPrimary.styles";
export var EButtonSize;
(function (EButtonSize) {
    EButtonSize["Inline"] = "INLINE";
    EButtonSize["Small"] = "SMALL";
    EButtonSize["Medium"] = "MEDIUM";
    EButtonSize["Large"] = "LARGE";
    EButtonSize["ExtraLarge"] = "EXTRA_LARGE";
    EButtonSize["Full"] = "FULL";
})(EButtonSize || (EButtonSize = {}));
export var ETextSize;
(function (ETextSize) {
    ETextSize["Small"] = "SMALL";
    ETextSize["Medium"] = "MEDIUM";
    ETextSize["Large"] = "LARGE";
})(ETextSize || (ETextSize = {}));
var ButtonPrimary = /** @class */ (function (_super) {
    __extends(ButtonPrimary, _super);
    function ButtonPrimary() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // Render
    ButtonPrimary.prototype.render = function () {
        var _a = this.props, title = _a.title, _b = _a.titleStyles, titleStyles = _b === void 0 ? {} : _b, _c = _a.isLoading, isLoading = _c === void 0 ? false : _c, _d = _a.isDisabled, isDisabled = _d === void 0 ? false : _d, _e = _a.isInverted, isInverted = _e === void 0 ? false : _e, tintColor = _a.tintColor, onPress = _a.onPress, backgroundColor = _a.backgroundColor, _f = _a.buttonSize, buttonSize = _f === void 0 ? EButtonSize.Inline : _f, _g = _a.textSize, textSize = _g === void 0 ? ETextSize.Medium : _g, rest = __rest(_a, ["title", "titleStyles", "isLoading", "isDisabled", "isInverted", "tintColor", "onPress", "backgroundColor", "buttonSize", "textSize"]);
        return (_jsx(S.Wrap
        // onPressOut as fix for onPress not always firing when releasing button inside its bounds
        // onPressOut={Platform.OS === "web" ? onPress : undefined}
        , __assign({ 
            // onPressOut as fix for onPress not always firing when releasing button inside its bounds
            // onPressOut={Platform.OS === "web" ? onPress : undefined}
            onPress: onPress, buttonSize: buttonSize, isLoading: isLoading, disabled: isDisabled, isInverted: isInverted, backgroundColor: backgroundColor }, rest, { children: _jsxs(S.InnerWrap, { children: [isLoading && (_jsx(S.ActivityIndicatorStyled, { tintColor: tintColor, isInverted: isInverted })), _jsx(S.Title, __assign({ tintColor: tintColor, style: titleStyles, isInverted: isInverted, textSize: textSize }, { children: title }))] }) })));
    };
    return ButtonPrimary;
}(React.PureComponent));
// eslint-disable-next-line import/no-default-export
export default ButtonPrimary;
