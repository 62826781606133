import { createRoutine } from "../utils/routine.utils";
export var getContacts = createRoutine("CONTACTS_GET");
export var getContact = createRoutine("CONTACT_GET");
export var getContactsByAccountIds = createRoutine("CONTACTS_BY_ACCOUNT_ID_GET");
export var createContact = createRoutine("CONTACT_CREATE");
export var createContactBroker = createRoutine("CONTACT_CREATE_BROKER");
export var updateContactBroker = createRoutine("CONTACT_UPDATE_BROKER");
export var searchContacts = createRoutine("CONTACTS_SEARCH");
export var updateContact = createRoutine("CONTACT_UPDATE");
export var breakUserConnection = createRoutine("CONTACT_BREAK_USERS_CONNECTION");
export var kycContact = createRoutine("CONTACT_KYC");
export var deleteContact = createRoutine("CONTACT_DELETE");
export var getIbans = createRoutine("CONTACTS_IBANS_GET");
export var addIban = createRoutine("CONTACT_IBAN_ADD");
export var deleteIban = createRoutine("CONTACT_IBAN_DELETE");
export var addIbanForRentDepositPayout = createRoutine("CONTACT_IBAN_ADD_FOR_RENT_DEPOSIT_PAYOUT");
export var getContactsPaged = createRoutine("CONTACTS_PAGED_GET");
