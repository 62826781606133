var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "../../utils/hooks.utils";
import { generateActions } from "./generator.actions";
import { generateSelectors } from "./generator.selectors";
var generateUseDetail = function (params) {
    var _a = generateSelectors(params), getDetail = _a.getDetail, isFetchingDetail = _a.isFetchingDetail, fetchDetailError = _a.fetchDetailError;
    var getDetailStart = generateActions(params).getDetailStart;
    var useDetail = function (parameters) {
        var id = parameters.id, customPath = parameters.customPath, extraQueryParams = parameters.extraQueryParams, _a = parameters.shouldRefetch, shouldRefetch = _a === void 0 ? false : _a, skipLegacy = parameters.skipLegacy;
        // Redux
        var dispatch = useDispatch();
        var detail = useSelector(function (state) {
            return getDetail(state, id, customPath);
        });
        var isFetching = useSelector(function (state) {
            return isFetchingDetail(state, id, customPath);
        });
        var fetchError = useSelector(function (state) {
            return fetchDetailError(state, id, customPath);
        });
        // Data
        var refetch = function () {
            if (isFetching) {
                return;
            }
            if (!id && !customPath) {
                return;
            }
            dispatch(getDetailStart.getAction({
                id: id,
                customPath: customPath,
                extraQueryParams: extraQueryParams,
                skipLegacy: skipLegacy,
            }));
        };
        // Lifecycle
        useEffect(function () {
            if (isFetching) {
                return;
            }
            if (!id && !customPath) {
                return;
            }
            if (!shouldRefetch && !!detail) {
                return;
            }
            dispatch(getDetailStart.getAction({
                id: id,
                customPath: customPath,
                extraQueryParams: extraQueryParams,
                skipLegacy: skipLegacy,
            }));
        }, [id, customPath, extraQueryParams]);
        return {
            detail: detail,
            isFetching: isFetching || false,
            fetchError: fetchError,
            refetch: refetch,
        };
    };
    return useDetail;
};
var generateUsePaged = function (params) {
    var getPaged = generateSelectors(params).getPaged;
    var getPagedStart = generateActions(params).getPagedStart;
    var usePaged = function (params) {
        var query = params.query, customPath = params.customPath, skipLegacyCount = params.skipLegacyCount, skipLegacy = params.skipLegacy;
        var prevQuery = usePrevious(query);
        var prevCustomPath = usePrevious(customPath);
        // Redux
        var dispatch = useDispatch();
        var paged = useSelector(function (state) {
            return getPaged(state, query, customPath);
        });
        var _a = paged || {}, totalItems = _a.totalItems, totalPages = _a.totalPages, items = _a.items, isFetching = _a.isFetching, fetchError = _a.fetchError, isExhausted = _a.isExhausted;
        // Data
        var refetch = function () {
            if (isFetching) {
                return;
            }
            if (!query) {
                return;
            }
            dispatch(getPagedStart.getAction({
                query: query,
                customPath: customPath,
                skipLegacyCount: skipLegacyCount,
                skipLegacy: skipLegacy,
            }));
        };
        // Lifecycle
        useEffect(function () {
            if (isFetching) {
                return;
            }
            if (!query) {
                return;
            }
            // TODO: Prevent deep equal check
            if (isEqual(query, prevQuery) && isEqual(customPath, prevCustomPath)) {
                return;
            }
            dispatch(getPagedStart.getAction({
                query: query,
                customPath: customPath,
                skipLegacyCount: skipLegacyCount,
                skipLegacy: skipLegacy,
            }));
        }, [query, customPath]);
        var hasData = (items || []).length > 0;
        return {
            totalItems: totalItems,
            totalPages: totalPages,
            items: items,
            isFetching: isFetching || false,
            isExhausted: isExhausted,
            hasData: hasData,
            fetchError: fetchError,
            refetch: refetch,
        };
    };
    return usePaged;
};
var generateUseGetAll = function (params) {
    var getAllWhere = generateSelectors(params).getAllWhere;
    var getAllWhereStart = generateActions(params).getAllWhereStart;
    var useGetAll = function (params) {
        var query = params.query, customPath = params.customPath;
        var prevQuery = usePrevious(query);
        var prevCustomPath = usePrevious(customPath);
        // Redux
        var dispatch = useDispatch();
        var all = useSelector(function (state) {
            return getAllWhere(state, query, customPath);
        });
        var _a = all || {}, items = _a.items, isFetching = _a.isFetching, fetchError = _a.fetchError, isExhausted = _a.isExhausted;
        // Data
        var refetch = function () {
            if (isFetching) {
                return;
            }
            if (!query) {
                return;
            }
            dispatch(getAllWhereStart.getAction({
                query: query,
                customPath: customPath,
            }));
        };
        // Lifecycle
        useEffect(function () {
            if (isFetching) {
                return;
            }
            if (!query) {
                return;
            }
            // TODO: Prevent deep equal check
            if (isEqual(query, prevQuery) && isEqual(customPath, prevCustomPath)) {
                return;
            }
            dispatch(getAllWhereStart.getAction({
                query: query,
                customPath: customPath,
            }));
        }, [query, customPath]);
        var hasData = (items || []).length > 0;
        return {
            items: items,
            isFetching: isFetching || false,
            isExhausted: isExhausted,
            hasData: hasData,
            fetchError: fetchError,
            refetch: refetch,
        };
    };
    return useGetAll;
};
var generateUseInfiniteLoad = function (params) {
    var getInfiniteLoad = generateSelectors(params).getInfiniteLoad;
    var getInfiniteLoadStart = generateActions(params).getInfiniteLoadStart;
    var useInfiniteLoad = function (params) {
        var query = params.query, customPath = params.customPath;
        var prevQuery = usePrevious(query);
        var prevCustomPath = usePrevious(customPath);
        // Redux
        var dispatch = useDispatch();
        var infiniteLoad = useSelector(function (state) {
            return getInfiniteLoad(state, query, customPath);
        });
        var _a = infiniteLoad || {}, totalItems = _a.totalItems, totalPages = _a.totalPages, items = _a.items, isFetching = _a.isFetching, isExhausted = _a.isExhausted, fetchError = _a.fetchError;
        // Data
        var loadMore = function (params) {
            var _a = params.shouldRefresh, shouldRefresh = _a === void 0 ? false : _a;
            if (isFetching) {
                return;
            }
            if (!query) {
                return;
            }
            if (isExhausted && !shouldRefresh) {
                return;
            }
            dispatch(getInfiniteLoadStart.getAction({
                query: query,
                customPath: customPath,
                refresh: shouldRefresh,
            }));
        };
        var refresh = function () {
            loadMore({ shouldRefresh: true });
        };
        // Lifecycle
        useEffect(function () {
            if (isFetching) {
                return;
            }
            if (!query) {
                return;
            }
            // TODO: Prevent deep equal check
            if (isEqual(query, prevQuery) && isEqual(customPath, prevCustomPath)) {
                return;
            }
            dispatch(getInfiniteLoadStart.getAction({
                query: query,
                customPath: customPath,
                refresh: true,
            }));
        }, [query, customPath]);
        var hasData = (items || []).length > 0;
        return {
            totalItems: totalItems,
            totalPages: totalPages,
            items: items,
            isFetching: isFetching || false,
            isExhausted: isExhausted,
            hasData: hasData,
            fetchError: fetchError,
            loadMore: function () { return loadMore({ shouldRefresh: false }); },
            refresh: refresh,
        };
    };
    return useInfiniteLoad;
};
var generateUseCount = function (params) {
    var _a = generateSelectors(params), getCount = _a.getCount, isFetchingCount = _a.isFetchingCount, fetchCountError = _a.fetchCountError;
    var getCountStart = generateActions(params).getCountStart;
    var useCount = function (params) {
        var query = params.query, customPath = params.customPath, _a = params.shouldRefetch, shouldRefetch = _a === void 0 ? false : _a;
        var prevQuery = usePrevious(query);
        var prevCustomPath = usePrevious(customPath);
        // Redux
        var dispatch = useDispatch();
        var count = useSelector(function (state) {
            return getCount(state, query, customPath);
        });
        var isFetching = useSelector(function (state) {
            return isFetchingCount(state, query, customPath);
        });
        var fetchError = useSelector(function (state) {
            return fetchCountError(state, query, customPath);
        });
        // Data
        // TODO: Refetch?
        // Lifecycle
        useEffect(function () {
            if (isFetching) {
                return;
            }
            if (!query) {
                return;
            }
            if (!shouldRefetch && count !== undefined) {
                return;
            }
            // TODO: Prevent deep equal check
            if (isEqual(query, prevQuery) && isEqual(customPath, prevCustomPath)) {
                return;
            }
            dispatch(getCountStart.getAction({
                query: query,
                customPath: customPath,
            }));
        }, [query, customPath]);
        return {
            count: count,
            isFetching: isFetching || false,
            fetchError: fetchError,
        };
    };
    return useCount;
};
var generateUseUpdate = function (params) {
    var _a = generateSelectors(params), getDetail = _a.getDetail, isUpdatingSelector = _a.isUpdating, updateError = _a.updateError;
    var _b = generateActions(params), updateStart = _b.updateStart, updateMembersStart = _b.updateMembersStart, updateEmployeesStart = _b.updateEmployeesStart;
    var useUpdate = function (params) {
        var defaultId = params.id;
        // State
        var _a = useState(defaultId), id = _a[0], setId = _a[1];
        // Redux
        var dispatch = useDispatch();
        var detail = useSelector(function (state) {
            return id ? getDetail(state, id) : undefined;
        });
        var isUpdating = useSelector(function (state) {
            return id ? isUpdatingSelector(state, id) : false;
        });
        var error = useSelector(function (state) {
            return id ? updateError(state, id) : undefined;
        });
        // Data
        var update = function (params, newId) {
            if (newId) {
                setId(newId);
            }
            // Fallback because set state is async
            var tempId = newId || id;
            if (!tempId) {
                console.log("Could not perform update since the entity ID was not set nor passed");
                return;
            }
            dispatch(updateStart.getAction(__assign({ id: tempId }, params)));
        };
        var updateMembers = function (params, newId) {
            if (newId) {
                setId(newId);
            }
            // Fallback because set state is async
            var tempId = newId || id;
            if (!tempId) {
                console.log("Could not perform update members since the entity ID was not set nor passed");
                return;
            }
            dispatch(updateMembersStart.getAction(__assign({ id: tempId }, params)));
        };
        var updateEmployees = function (params, newId) {
            if (newId) {
                setId(newId);
            }
            // Fallback because set state is async
            var tempId = newId || id;
            if (!tempId) {
                console.log("Could not perform update employees since the entity ID was not set nor passed");
                return;
            }
            dispatch(updateEmployeesStart.getAction(__assign({ id: tempId }, params)));
        };
        // Lifecycle
        useEffect(function () {
            setId(defaultId);
        }, [defaultId]);
        return {
            update: update,
            updateMembers: updateMembers,
            updateEmployees: updateEmployees,
            detail: detail,
            isUpdating: isUpdating,
            error: error,
        };
    };
    return useUpdate;
};
var generateUseRemove = function (params) {
    var _a = generateSelectors(params), isRemovingSelector = _a.isRemoving, removeError = _a.removeError;
    var removeStart = generateActions(params).removeStart;
    var useRemove = function (params) {
        var id = params.id;
        // Redux
        var dispatch = useDispatch();
        var isRemoving = useSelector(function (state) {
            return id ? isRemovingSelector(state, id) : false;
        });
        var error = useSelector(function (state) {
            return id ? removeError(state, id) : undefined;
        });
        var remove = function (_a) {
            var onSuccess = _a.onSuccess;
            if (!id)
                return;
            dispatch(removeStart.getAction({
                id: id,
                onSuccess: function () {
                    if (onSuccess)
                        onSuccess();
                },
            }));
        };
        return {
            remove: remove,
            isRemoving: isRemoving,
            error: error,
        };
    };
    return useRemove;
};
var generateUseArchiving = function (params) {
    var _a = generateSelectors(params), isArchivingSelector = _a.isArchiving, archivingError = _a.archivingError;
    var _b = generateActions(params), archiveStart = _b.archiveStart, unarchiveStart = _b.unarchiveStart;
    var useArchive = function (params) {
        var defaultId = params.id;
        // State
        var _a = useState(defaultId), id = _a[0], setId = _a[1];
        // Redux
        var dispatch = useDispatch();
        var isArchiving = useSelector(function (state) {
            return id ? isArchivingSelector(state, id) : false;
        });
        var error = useSelector(function (state) {
            return id ? archivingError(state, id) : undefined;
        });
        // Actions
        var archive = function (params, newId) {
            if (newId) {
                setId(newId);
            }
            // Fallback because set state is async
            var tempId = newId || id;
            if (!tempId) {
                console.log("Could not perform archive since the entity ID was not set nor passed");
                return;
            }
            dispatch(archiveStart.getAction(__assign({ id: tempId }, params)));
        };
        // Lifecycle
        useEffect(function () {
            setId(defaultId);
        }, [defaultId]);
        return {
            archive: archive,
            isArchiving: isArchiving,
            error: error,
        };
    };
    var useUnarchive = function (params) {
        var defaultId = params.id;
        // State
        var _a = useState(), id = _a[0], setId = _a[1];
        // Redux
        var dispatch = useDispatch();
        var isUnArchiving = useSelector(function (state) {
            return id ? isArchivingSelector(state, id) : false;
        });
        var error = useSelector(function (state) {
            return id ? archivingError(state, id) : undefined;
        });
        // Actions
        var unarchive = function (params, newId) {
            if (newId) {
                setId(newId);
            }
            // Fallback because set state is async
            var tempId = newId || id;
            if (!tempId) {
                console.log("Could not perform archive since the entity ID was not set nor passed");
                return;
            }
            dispatch(unarchiveStart.getAction(__assign({ id: tempId }, params)));
        };
        // Lifecycle
        useEffect(function () {
            setId(defaultId);
        }, [defaultId]);
        return {
            unarchive: unarchive,
            isUnArchiving: isUnArchiving,
            error: error,
        };
    };
    return { useArchive: useArchive, useUnarchive: useUnarchive };
};
export var generateHooks = function (params) {
    var useDetail = generateUseDetail(params);
    var usePaged = generateUsePaged(params);
    var useUpdate = generateUseUpdate(params);
    var useCount = generateUseCount(params);
    var useInfiniteLoad = generateUseInfiniteLoad(params);
    var useRemove = generateUseRemove(params);
    var useGetAll = generateUseGetAll(params);
    var _a = generateUseArchiving(params), useArchive = _a.useArchive, useUnarchive = _a.useUnarchive;
    return {
        useDetail: useDetail,
        usePaged: usePaged,
        useInfiniteLoad: useInfiniteLoad,
        useGetAll: useGetAll,
        useUpdate: useUpdate,
        useCount: useCount,
        useRemove: useRemove,
        useArchive: useArchive,
        useUnarchive: useUnarchive,
    };
};
